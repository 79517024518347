<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Rekap Pendukung</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        style="width: 20px"
                        class="text-center align-middle"
                        rowspan="2"
                      >
                        NO
                      </th>
                      <th class="text-center align-middle" rowspan="2">DESA</th>
                      <th class="text-center align-middle" colspan="4">
                        PENDUKUNG
                      </th>
                      <th class="text-center align-middle" colspan="2">
                        TOTAL
                      </th>
                      <th
                        style="width: 100px"
                        class="text-center align-middle"
                        rowspan="2"
                      >
                        PERSENTASI CAPAIAN
                      </th>
                    </tr>
                    <tr>
                      <th style="width: 100px" class="text-center align-middle">
                        L
                      </th>
                      <th style="width: 100px" class="text-center align-middle">
                        %
                      </th>
                      <th style="width: 100px" class="text-center align-middle">
                        P
                      </th>
                      <th style="width: 100px" class="text-center align-middle">
                        %
                      </th>
                      <th style="width: 100px" class="text-center align-middle">
                        TARGET
                      </th>
                      <th style="width: 100px" class="text-center align-middle">
                        PENDUKUNG
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rekap, index) in dataRekap" :key="rekap.id_kec">
                      <td class="text-center" style="width: 20px">
                        {{ index + 1 }}
                      </td>
                      <td class="text-uppercase">{{ rekap.nama }}</td>
                      <td class="text-center">
                        {{ rekap.male }}
                      </td>
                      <td class="text-center">
                        {{ hitungPersen(rekap.male, rekap.total) }} %
                      </td>
                      <td class="text-center">
                        {{ rekap.female }}
                      </td>
                      <td class="text-center">
                        {{ hitungPersen(rekap.female, rekap.total) }} %
                      </td>
                      <td class="text-center">
                        {{ rekap.target }}
                      </td>
                      <td class="text-center">
                        {{ rekap.total }}
                      </td>
                      <td class="text-center">
                        {{ hitungPersen(rekap.total, rekap.target) }} %
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-center text-uppercase" colspan="2">
                        Total
                      </th>
                      <th class="text-center">{{ totalMale }}</th>
                      <th class="text-center">
                        {{ hitungPersen(totalMale, totalTotal) }} %
                      </th>
                      <th class="text-center">{{ totalFemale }}</th>
                      <th class="text-center">
                        {{ hitungPersen(totalFemale, totalTotal) }} %
                      </th>
                      <th class="text-center">{{ totalTarget }}</th>
                      <th class="text-center">{{ totalTotal }}</th>
                      <th class="text-center">
                        {{ hitungPersen(totalTotal, totalTarget) }} %
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <a href="javascript:window.history.go(-1);" class="btn btn-default"
            >Kembali</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      dataRekap: [],
      loading: false,
      totalMale: 0,
      totalFemale: 0,
      totalTarget: 0,
      totalTotal: 0,
      id: "",
      nama_kecamatan: "",
    };
  },

  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.id = id;
      this.fetchData();
    } else {
      this.id = "";
    }
  },
  watch: {
    dataRekap: {
      handler: function (newVal) {
        this.calculateTotals(newVal);
      },
      deep: true,
    },
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/rekap/kecamatan/" + this.id, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.dataRekap = response.data.data;
          this.nama_kecamatan = response.data.kecamatan;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    hitungPersen(a, b) {
      if (a == 0) {
        return 0;
      } else {
        const percentage = (a / b) * 100;
        return percentage.toFixed(2);
      }
    },
    calculateTotals(data) {
      this.totalMale = data.reduce(
        (acc, curr) => parseInt(acc) + parseInt(curr.male),
        0
      );
      this.totalFemale = data.reduce(
        (acc, curr) => parseInt(acc) + parseInt(curr.female),
        0
      );
      this.totalTarget = data.reduce(
        (acc, curr) => parseInt(acc) + parseInt(curr.target),
        0
      );
      this.totalTotal = data.reduce(
        (acc, curr) => parseInt(acc) + parseInt(curr.total),
        0
      );
    },
  },
};
</script>